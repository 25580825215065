import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TitleArea from '../components/TitleArea'
import { Container, Columns, Column, Content, Section } from 'bloomer'

export default () => (
  <Layout>
    <SEO title="Vaccination Policy" alias="dog-boarding" />
    <TitleArea title="Vaccination Policy" />
    <Section>
      <Container className="container--narrow">
        <Columns>
          <Column>
            <Content>
              <h2>Dogs</h2>
              <p>
                <strong>
                  All dogs must have a Vaccination Certificate from a
                  veterinarian to show vaccinations are current before being
                  admitted to the kennel.&nbsp;This Certificate must be
                  presented each time your dog stays at Shady Lane Boarding
                  Kennel.
                </strong>
              </p>
              <p>
                All vaccinations must be administered at least 7 days prior to
                boarding.
              </p>
              <h3>Vaccinations Required</h3>
              <ul>
                <li>
                  Parvovirus, Leptospirosis, Parainfluenza, Distemper,
                  Adenovirus
                </li>
                <li>Rabies</li>
                <li>Bordetella (Canine Cough)</li>
              </ul>
              <p>
                Please note that the Bordetella Vaccine is administered by many
                veterinarians by request only.
              </p>
              <p>
                Puppies must be 12 weeks of age and have vaccinations complete
                before boarding.
              </p>
              <h2>Cats</h2>
              <p>
                <strong>
                  All cats must have a Vaccination Certificate from a
                  veterinarian to show vaccinations are current before being
                  admitted to the kennel. This Certificate must be presented
                  each time your cat stays at Shady Lane Boarding Kennel.
                </strong>
              </p>
              <p>
                All vaccinations must be administered at least 7 days prior to
                boarding.
              </p>
              <h3>Vaccinations Required</h3>
              <ul>
                <li>Rabies</li>
                <li>
                  FVRCP —Feline Viral Rhinotracheitis, Calici,
                  Panleukopenia(Distemper)
                </li>
              </ul>
              <p>
                Kittens must be 16 weeks of age and have all vaccinations
                complete before boarding.
              </p>
            </Content>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)
